/* 

To make sure that this sidebar is relatively consistent on all devices

1. Make sure .sidebar:padding-left + .sidebar.icon:width == .sidebar:width 
2. Make sure .sidebar:top 
    + (.sidebar.icon:height * .sidebar.icon:margin-bottom) * number of icons 
    + (.line-top:height + .line-top:margin-bottom)
    + .line-bottom:height
    == 100vh

    0.5 + (8.5 * 4) + 55 + 2.5 + 8
*/

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
        visibility: visible;
    }
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(100%);
        visibility: visible;
    }
    100% {
        transform: translateY(0);
        visibility: visible;
    }
}

.sidebar {
    position: fixed;
    height: 100%;
    width: 11vh;
    z-index: 1;
    top: 0;
    padding-left: 5vh;
    display: block; /* Make the links appear below each other instead of side-by-side */
    text-align: center;
    visibility: hidden;
    background-color: rgba(54, 76, 99, 0.05);
    .icon {
        height: 6vh;
        width: 6vh;
        margin-bottom: 2.5vh;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translateZ(0) scale(1, 1);
        transform: translateZ(0) scale(1, 1);
        &1 {
            animation: 1s ease-out 1s 1 forwards slideInFromBottom; /*duration, animation behavior, delay, times to play, name*/
        }
        &2 {
            animation: 1s ease-out 1.25s 1 forwards slideInFromBottom; /*duration, animation behavior, delay, times to play, name*/
        }
        &3 {
            animation: 1s ease-out 1.5s 1 forwards slideInFromBottom; /*duration, animation behavior, delay, times to play, name*/
        }
        &4 {
            animation: 1s ease-out 1.75s 1 forwards slideInFromBottom; /*duration, animation behavior, delay, times to play, name*/
        }
    }
}

.sidebar > a {
    &:hover {
        opacity: 0.7;
        transition: all 0.3s ease;
    }
}

.line-top {
    visibility: hidden;
    animation: 1s ease-out 0s 1 forwards slideInFromBottom; /*duration, animation behavior, delay, times to play, name*/
    height: 55.5vh;
    margin-bottom: 2.5vh;
}

.line-bottom {
    visibility: hidden;
    animation: 1s ease-out 2s 1 forwards slideInFromBottom; /*duration, animation behavior, delay, times to play, name*/
    height: 8vh;
}
