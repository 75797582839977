@charset "UTF-8";

.coding {
    line-height: 100%;
}

.coding-wrapper {
    font-family: Poppins, sans-serif;
    html,
    .coding {
        background-image: linear-gradient(
                to bottom,
                #263f5df7 10%,
                #163355f7 90%
            ),
            url("../images/background/dark_background.jpg");
    }
    html,
    .coding {
        margin: 0;
    }
}

.coding-wrapper {
    background-image: linear-gradient(to bottom, #263f5df7 10%, #163355f7 90%),
        url("../images/background/dark_background.jpg");
    height: 100vh;
    .title-wrapper {
        display: block; /* Make the links appear below each other instead of side-by-side */
        text-align: center;
        margin-bottom: 2rem;
        color: #f1f4f8;
        .title {
            font-size: calc(
                36px + (66 - 36) * ((100vw - 600px) / (1600 - 600))
            );
            margin-bottom: -25px;
        }
        .title-line {
            width: calc(82px + (127 - 82) * ((100vw - 600px) / (1600 - 600)));
        }
    }
    h4 {
        margin: 0px;
    }
}

.project-table {
    color: #f1f4f8;
}

.icons-container {
    .icon {
        height: 24px;
        width: auto;
        padding-right: 16px;
    }
    width: 80px;
}

.icon-back {
    height: calc(14px + (18 - 14) * ((100vw - 600px) / (1600 - 600)));
    width: auto;
}

.back-text {
    color: #dfe3e8;
    font-family: Poppins, sans-serif;
    font-size: calc(
        14px + (18 - 14) * ((100vw - 600px) / (1600 - 600))
    ) !important;
    padding-left: 5px;
}

@media all and(min-width:800px) {
    .custom-nav-link-coding {
        padding-left: 10vw;
    }
}

.custom-nav-link-coding {
    h4:hover {
        color: #99a0a7;
    }
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
    color: #f1f4f8;
    background-color: rgba(255, 255, 255, 0.1);
}

.table th {
    border: rgba(245, 204, 124, 1) solid 2px !important;
    border-top-style: hidden !important;
    border-left-style: hidden !important;
    border-right-style: hidden !important;
}

.custom-row {
    border-bottom-style: hidden !important;
    border-left-style: hidden !important;
    border-right-style: hidden !important;
}

.table-container {
    width: 80vw;
    height: 60vh;
    max-height: 95vh;
    max-width: 95%;
    overflow-y: scroll;
    overflow-x: hide;
    font-size: calc(13px + (16 - 13) * ((100vw - 600px) / (1600 - 600)));
}

.table-container::-webkit-scrollbar {
    width: 2px;
    background-color: rgba(255, 255, 255, 0.1);
    visibility: hidden;
}

.table-container::-webkit-scrollbar-thumb {
    background-color: rgba(241, 244, 248, 0.4);
    visibility: hidden;
}

.table-container:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}

.table-container:hover::-webkit-scrollbar {
    visibility: visible;
}
