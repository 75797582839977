@charset "UTF-8";

.contact-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .title-wrapper {
        text-align: center;
        .title {
            font-size: calc(
                42px + (66 - 42) * ((100vw - 600px) / (1600 - 600))
            );
            margin-bottom: -25px;
        }
        .title-line {
            width: calc(95px + (127 - 95) * ((100vw - 600px) / (1600 - 600)));
        }
    }

    .contact-icon {
        height: 8vh;
        width: 8vh;
        margin-right: 6vw;
    }

    @media screen and (max-width: 800px) {
        .contact-icon {
            height: 5vh !important;
            width: 5vh !important;
            margin-right: 6vh !important;
        }
    }

    @media screen and (max-height: 400px) {
        .contact-icon {
            height: 5vw !important;
            width: 5vw !important;
            margin-right: 8vw !important;
        }
    }

    .contact-icon-4 {
        margin-right: 0px !important;
    }

    .contact-icon-row {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 10vh;
    }

    .contact-icon-row > a {
        &:hover {
            opacity: 0.7;
            transition: all 0.3s ease;
        }
    }

    .btn-email {
        background: #dfe3e8;
        color: #0b1d24;
        border: 3px solid #f5cc7c;
        border-radius: 40px;
        padding: 5px 25px;
        font-family: Poppins, sans-serif;
        font-size: calc(18px + (20 - 18) * ((100vw - 600px) / (1600 - 600)));
        margin-bottom: 10vh;
        &:hover {
            opacity: 0.7;
            transition: all 0.3s ease;
        }
    }

    .contact-text {
        font-size: 20px;
        margin-bottom: 10vh;
        margin-top: 10vh;
        font-family: Josefin Sans, sans-serif;
    }
}

.contact-cooking-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .title-wrapper {
        text-align: center;
        .title {
            font-size: calc(
                42px + (66 - 42) * ((100vw - 600px) / (1600 - 600))
            );
            margin-bottom: -25px;
        }
        .title-line {
            width: calc(95px + (127 - 95) * ((100vw - 600px) / (1600 - 600)));
        }
    }

    .contact-icon {
        height: 8vh;
        width: 8vh;
        margin-right: 6vw;
    }

    @media screen and (max-width: 1200px) {
        .contact-icon {
            height: 4vh !important;
            width: 4vh !important;
            margin-right: 6vw !important;
        }
    }

    .contact-icon-4 {
        margin-right: 0px !important;
    }

    @media screen and (max-width: 800px) {
        .contact-icon {
            height: 5vh !important;
            width: 5vh !important;
            margin-right: 6vh !important;
        }
    }

    @media screen and (max-height: 400px) {
        .contact-icon {
            height: 5vw !important;
            width: 5vw !important;
            margin-right: 8vw !important;
        }
    }

    .contact-icon-row {
        width: 50vw;
        margin: 0 auto;
    }

    .contact-icon-row > a {
        &:hover {
            opacity: 0.7;
            transition: all 0.3s ease;
        }
    }

    .btn-email {
        background: #dfe3e8;
        color: #0b1d24;
        border: 3px solid #f5cc7c;
        border-radius: 40px;
        padding: 5px 25px;
        font-family: Poppins, sans-serif;
        font-size: calc(18px + (20 - 18) * ((100vw - 600px) / (1600 - 600)));
        margin-bottom: 10vh;
        &:hover {
            opacity: 0.7;
            transition: all 0.3s ease;
        }
    }

    .contact-text {
        font-size: calc(20px + (24 - 20) * ((100vw - 600px) / (1600 - 600)));
        margin-bottom: 5vh;
        margin-top: 10vh;
        font-family: Josefin Sans, sans-serif;
    }
}
