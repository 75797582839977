@charset "UTF-8";

.cooking,
html {
    background-image: linear-gradient(to bottom, #e8edf3f5, #e8edf3f5),
        url("../images/background/dark_background.jpg");
    font-family: Poppins, sans-serif;
}

.custom-nav-link-cooking {
    padding-top: 10vh;
    .back-text {
        color: black;
    }
    h4 {
        margin: 0px;
    }

    h4:hover {
        color: #99a0a7;
    }
}

@media all and(min-width:580px) {
    .custom-nav-link-cooking {
        padding-left: 8vw;
    }
}

.gallery-container {
    padding-bottom: 20vh;
}

.about-cooking-wrapper {
    border: 0px;
    background-image: linear-gradient(to bottom, #e8edf3f5, #d2d7ddf5),
        url("../images/background/dark_background.jpg");
    padding-bottom: 12rem;
    .title-wrapper {
        display: block; /* Make the links appear below each other instead of side-by-side */
        text-align: center;
        margin-bottom: 2rem;
        margin-top: 4rem;
        .title {
            font-size: calc(
                36px + (66 - 36) * ((100vw - 600px) / (1600 - 600))
            );
            margin-bottom: -25px;
            color: #0b1d24;
        }
        .title-line {
            width: calc(82px + (127 - 82) * ((100vw - 600px) / (1600 - 600)));
        }
    }
    .image {
        position: relative;
        display: block;
        width: 70%;
        max-width: 80%;
        object-fit: cover;
        border-radius: 1rem;
        border: 3px solid #8eafce;
        overflow: hidden;
        margin: 0px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5vh;
    }

    .text {
        position: relative;
        margin: 0vh auto;
        max-width: 100%;
        max-height: 100%;
        position: relative;
        font-family: Josefin Sans, sans-serif;
        font-size: calc(16px + (20 - 16) * ((100vw - 600px) / (1600 - 600)));
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #0b1d24;
    }
    .bottom-p {
        margin-bottom: 0px;
    }
}

@media screen and (min-width: 991px) and (min-height: 450px) {
    // this css will only be used when the screen size bigger than 800px
    .about-cooking-wrapper {
        .image {
            left: 60px;
        }
        .text {
            .btn-resume {
                margin-top: 3vh !important;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    // this css will only be used when the screen size smaller than 1000px
    .text {
        margin: 0vh auto !important;
    }
}

@media all and(max-width:480px) {
    .about-cooking-wrapper {
        .text {
            text-align: center !important;
        }
    }
}

.contact-cooking-wrapper {
    background-image: linear-gradient(to bottom, #d2d7ddf5, #acb5bff5),
        url("../images/background/dark_background.jpg");
    padding-bottom: 30px;
    border: 0px;
    height: 95vh;
    position: relative;
    color: #0b1d24;
    .btn-email {
        border: 3px solid #8eafce;
    }
}
