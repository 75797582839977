@charset "UTF-8";

.timeline-container {
    margin: 40px auto;
    padding-left: 50px;
    font-family: Josefin Sans;
    overflow: hidden;
    a {
        color: #0b1e24;
    }
    h1 {
        font-size: calc(32px + (36 - 32) * ((100vw - 600px) / (1600 - 600)));
    }
    h2,
    h3 {
        font-size: calc(18px + (22 - 18) * ((100vw - 600px) / (1600 - 600)));
    }
    p {
        font-size: calc(16px + (18 - 16) * ((100vw - 600px) / (1600 - 600)));
    }
    p a {
        color: #0000ee;
    }
    a:focus {
        outline: none !important;
    }
}

.nav-pills .nav-link.active {
    background-color: #f5cc7ccc;
    color: #0b1e24;
}

.second-link {
    padding-left: 10px;
}

.timeline-content {
    font-size: 20px;
}

.timeline-tabs {
    font-size: calc(18px + (24 - 18) * ((100vw - 600px) / (1600 - 600)));
    padding-bottom: 30px;
}

.timeline-container-mobile {
    margin: 40px auto;
    width: 80%;
    font-family: Josefin Sans;
    overflow: hidden;
    a {
        color: #0b1e24;
    }
    h1 {
        font-size: calc(26px + (36 - 26) * ((100vw - 600px) / (1600 - 600)));
    }
    h2,
    h3 {
        font-size: calc(18px + (22 - 18) * ((100vw - 600px) / (1600 - 600)));
    }
    p {
        font-size: calc(16px + (18 - 16) * ((100vw - 600px) / (1600 - 600)));
    }
    p a {
        color: #0000ee;
    }
    a:focus {
        outline: none !important;
        border: none !important;
    }
    .nav-tabs .nav-link.active {
        background-color: #f5cc7ccc;
    }

    .nav-tabs .nav-link {
        border: none;
    }

    .nav-tabs {
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
    }
    .tabbable .nav-tabs .nav-link {
        white-space: nowrap;
    }

    .tab-content {
        margin: 5vh auto;
        overflow: auto;
        height: calc(
            100vh - 375px
        ); /* Replace 42px with the needed height. 100vh means the whole height of the browser's viewport */
        height: -webkit-calc(100vh - 375px);
        height: -moz-calc(100vh - 375px);
    }
}
