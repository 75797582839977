@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
        visibility: visible;
    }
    100% {
        transform: translateY(0);
        visibility: visible;
    }
}

@keyframes slideOutToTop {
    0% {
        transform: translateY(0%);
        visibility: visible;
    }
    100% {
        transform: translateY(-100%);
        visibility: visible;
    }
}

.custom-nav-link {
    color: #dfe3e8;
    font-family: Poppins, sans-serif;
    font-size: 24px;
}

.navbar-custom {
    background-color: rgba(54, 76, 99, 0.05);
    padding-left: 30%;
}

a:hover {
    color: #99a0a7;
}

.fade-enter,
.fade-appear {
    opacity: 1;
}

.fade-enter-active,
.fade-appear-active {
    opacity: 1;
    animation: 0.5s ease-out 0s 1 forwards slideInFromTop; /*duration, animation behavior, delay, times to play, name*/
}

.fade-leave {
    opacity: 1;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 1;
    animation: 1s ease-out 0s 1 forwards slideOutToTop; /*duration, animation behavior, delay, times to play, name*/
}

.custom-toggler.navbar-toggler {
    border-color: rgb(245, 204, 124);
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(245, 204, 124, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler {
    border: 3px solid transparent;
}
@media all and(max-width:800px) {
    .navbar {
        a {
            color: rgb(245, 204, 124);
            font-size: 22px;
            width: 100%;
        }
    }

    #responsive-navbar-nav {
        background-color: #364c6399;
    }
}
