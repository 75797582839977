@charset "UTF-8";

//Desktop Styles

.interest-item-left {
    margin: 3vh auto;
    width: 60vw;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;
    overflow: hidden;
    left: 3%;
}

.interest-item-right {
    margin: 3vh auto;
    width: 60vw;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;
    overflow: hidden;
    left: 2%;
}

.interest-item-body {
    background-color: #0b1d24;
    border: 4px solid #f5cc7c;
    border-radius: 30px;
    color: #dfe3e8;
}

.interest-item-body:hover {
    background-color: rgba(11, 29, 36, 0.75);
    .interest-item-image-left {
        filter: blur(0.3px);
        opacity: 0.97;
    }
    .interest-item-image-right {
        filter: blur(0.3px);
        opacity: 0.96;
    }
}

.interest-item-image-left {
    position: absolute;
    height: 80%;
    width: auto;
    top: 50%;
    transform: translateY(-50%);
    border: 4px solid #dfe3e8;
    border-radius: 30px;
    z-index: 1;
    left: -3%;
}

.interest-item-image-right {
    position: absolute;
    height: 80%;
    width: auto;
    top: 50%;
    transform: translateY(-50%);
    border: 4px solid #dfe3e8;
    border-radius: 30px;
    z-index: 1;
    right: -3%;
}

.interest-item-title {
    font-family: Poppins, sans-serif;
    font-size: 22px;
}

.interest-item-text {
    font-family: Josefin Sans, sans-serif;
    font-size: 16px;
}

//Mobile styles

.interest-item-left-mobile {
    margin: 3vh auto;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;
    overflow: hidden;
    left: -3%;
}

.interest-item-right-mobile {
    margin: 3vh auto;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;
    overflow: hidden;
    left: -6%;
}

@media screen and (min-width: 500px) {
    .interest-item-left-mobile {
        left: 0%;
    }

    .interest-item-right-mobile {
        left: -3%;
    }
}

@media screen and (max-height: 400px) {
    .interest-item-left-mobile {
        margin: 4vh auto;
    }

    .interest-item-right-mobile {
        margin: 4vh auto;
    }
}

.interest-item-body-mobile {
    background-color: #0b1d24;
    border: 4px solid #f5cc7c;
    border-radius: 20px;
    color: #dfe3e8;
    height: 100%;
}

.interest-item-image-left-mobile {
    position: absolute;
    height: 80%;
    width: auto;
    top: 50%;
    transform: translateY(-50%);
    border: 4px solid #dfe3e8;
    border-radius: 15px;
    z-index: 1;
    left: -3%;
}

.interest-item-image-right-mobile {
    position: absolute;
    height: 80%;
    width: auto;
    top: 50%;
    transform: translateY(-50%);
    border: 4px solid #dfe3e8;
    border-radius: 15px;
    z-index: 1;
    right: -3%;
}

.interest-item-title-mobile {
    font-family: Poppins, sans-serif;
    font-size: calc(14px + (26 - 14) * ((100vw - 600px) / (1600 - 600)));
}
