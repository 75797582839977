@charset "UTF-8";
body {
    background-image: linear-gradient(to bottom, #263f5df7, #263f5df7),
        url("../images/background/dark_background.jpg");
    background-color: #263f5d;
}

html {
    scroll-behavior: smooth;
}

.portfolio-body {
    padding: 0;
    margin: 0;
    font-family: Poppins, sans-serif;
    color: #dfe3e8;
    background-image: linear-gradient(to bottom, #263f5df7, #263f5df7),
        url("../images/background/dark_background.jpg");
    background-color: #263f5d;
}

@media all and(max-width:800px) {
    //disable horizontal scrolling on initial page load
    html,
    body {
        max-width: 100%;
        overflow-x: hidden;
        height: 100%;
    }
}

.p-indent {
    text-indent: 30px;
}

.title {
    font-weight: 500;
    font-size: 3em;
    padding: 20px;
    display: block;
}

.landing-wrapper {
    padding-top: 6rem;
    padding-bottom: calc(6rem + 74px);
    display: flex;
    align-items: center;
    height: 100vh;
    max-width: 100%; /* added */
    border: 0px;
    background-image: linear-gradient(to bottom, #263f5df7 10%, #163355f7 90%),
        url("../images/background/dark_background.jpg");
    .text-name {
        padding-left: 10vw;
        font-size: calc(38px + (68 - 38) * ((100vw - 600px) / (1600 - 600)));
        padding-bottom: 5vh;
    }

    .text-title {
        padding-left: 10vw;
        font-size: calc(32px + (56 - 32) * ((100vw - 600px) / (1600 - 600)));
    }
}

@media screen and (min-width: 991px) and (min-height: 450px) {
    // this css will only be used when the screen size bigger than 800px
    .about-wrapper {
        .image {
            left: 60px;
        }
        .text {
            .btn-resume {
                margin-top: 3vh !important;
            }
        }
    }
}

@media screen and (min-width: 1300px) {
    .about-wrapper {
        .text {
            .btn-resume {
                margin-top: 5vh !important;
            }
        }
    }
}

.about-wrapper {
    border: 0px;
    background-image: linear-gradient(to bottom, #163355f7, #a6afbaf7),
        url("../images/background/dark_background.jpg");
    padding-bottom: 12rem;
    .title-wrapper {
        display: block; /* Make the links appear below each other instead of side-by-side */
        text-align: center;
        margin-bottom: 2rem;
        margin-top: 4rem;
        .title {
            font-size: calc(
                36px + (66 - 36) * ((100vw - 600px) / (1600 - 600))
            );
            margin-bottom: -25px;
        }
        .title-line {
            width: calc(82px + (127 - 82) * ((100vw - 600px) / (1600 - 600)));
        }
    }
    .image {
        position: relative;
        display: block;
        width: 70%;
        max-width: 80%;
        object-fit: cover;
        border-radius: 1rem;
        border: 3px solid #dfe3e8;
        overflow: hidden;
        margin: 0px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5vh;
    }

    .text {
        position: relative;
        margin: 0vh auto;
        max-width: 100%;
        max-height: 100%;
        position: relative;
        font-family: Josefin Sans, sans-serif;
        font-size: calc(16px + (20 - 16) * ((100vw - 600px) / (1600 - 600)));
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn-resume {
            background: #f5cc7c;
            color: #0b1d24;
            border: 3px solid #dfe3e8;
            border-radius: 40px;
            padding: 5px 25px;
            font-family: Poppins, sans-serif;
            font-size: calc(
                16px + (20 - 16) * ((100vw - 600px) / (1600 - 600))
            );
            margin-top: 5vh;
            &:hover {
                opacity: 0.7;
                transition: all 0.3s ease;
            }
        }
    }
    .bottom-p {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 1024px) {
    // this css will only be used when the screen size smaller than 1000px
    .text {
        margin: 0vh auto !important;
    }
}

@media all and(max-width:480px) {
    .about-wrapper {
        .text {
            text-align: center !important;
        }
    }
}

.timeline-wrapper {
    padding-bottom: 30px;
    height: calc(110vh + (100vh - 110vh) * ((100vw - 600px) / (1600 - 600)));
    border: 0px;
    color: #0b1d24;
    background-image: linear-gradient(to bottom, #a6afbaf5, #bdc2caf5),
        url("../images/background/dark_background.jpg");
    .title-wrapper {
        display: block; /* Make the links appear below each other instead of side-by-side */
        text-align: center;
        margin-top: 10vh;
        .title {
            font-size: calc(
                36px + (66 - 36) * ((100vw - 600px) / (1600 - 600))
            );
            margin-bottom: -25px;
        }
        .title-line {
            width: calc(70px + (127 - 70) * ((100vw - 600px) / (1600 - 600)));
        }
    }
}

.interests-wrapper {
    background-image: linear-gradient(to bottom, #bdc2caf7, #2a425cf7),
        url("../images/background/dark_background.jpg");
    padding-bottom: 30px;
    border: 0px;
    color: #0b1d24;
    .title-wrapper {
        display: block; /* Make the links appear below each other instead of side-by-side */
        text-align: center;
        margin-top: 10vh;
        .title {
            font-size: calc(
                36px + (66 - 36) * ((100vw - 600px) / (1600 - 600))
            ); //36 is mobile, 66 is desktop
            margin-bottom: -25px;
        }
        .title-line {
            width: calc(67px + (127 - 67) * ((100vw - 600px) / (1600 - 600)));
        }
    }
}

.contact-wrapper {
    background-image: linear-gradient(to bottom, #2a425cf7, #193b62f7),
        url("../images/background/dark_background.jpg");
    padding-bottom: 30px;
    border: 0px;
    height: 95vh;
    position: relative;
}
