.btn-preclick {
  background: none;
  border: none;
}

.btn-postclick {
  background: none;
  border: none;
}

.btn-preclick:hover,
.btn-postclick:hover {
  color: #fff;
  background: none;
}

.btn-postclick:focus,
.btn-postclick.focus {
  color: #fff;
  background: none;
  box-shadow: 0 0 0 0.2rem rgba(60, 198, 171, 0.5);
}

.form-custom:focus {
  border-color: white;
  color: #495057;
  background-color: #fff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(26, 188, 156, 0.25);
}
